import React from "react"
import PropTypes from "prop-types"
import { Icon } from "react-icons-kit"
import { angleDown } from "react-icons-kit/fa/angleDown"
import { Flex } from "theme-ui"

function Arrow(props) {
  return (
    <Flex
      sx={{
        alignItems: "center",
        mx: "auto",
        position: "relative",
        zIndex: 1000,
      }}
      className="page-arrow"
    >
      <Icon
        icon={angleDown}
        size={35}
        style={{ padding: 0, margin: 0, marginTop: -20 }}
        className="fa fa-angle-down"
        aria-hidden="true"
      />
    </Flex>
  )
}

Arrow.propTypes = {}

export default Arrow
